// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col,Image } from "react-bootstrap";
import { Layout } from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_opciones.jpg";
import slick_items from "../../misc/dolar_mep_related/slick_items_dolares.json";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link } from "gatsby";

const pageTitle = 'Comprar Dólar MEP | Balanz';
const pageDesc = '¿Qué es Dólar MEP? Descubrí cómo comprar Dólar MEP de forma simple y rápida. Dolarizáte en Balanz.';

let text = `Conocé como invertir en Dólar MEP <br/><span class='highlightsbold'>y potenciar tus ahorros.</span> `;

const DolarMepPage = () => {
    const [xs, setXs] = useState(false);
    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 20}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

    const slickSettings = {
        centerMode: false,
        slidesToShow: 5,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 2920,
                settings: "unslick"
            },
            {
                breakpoint: 992,
                settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
            },
            {
                breakpoint: 576,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };
    useEffect(() => {
        apiRequest
            .get("/v1/faq/20")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })
                setFaq((old) => ({ ...old, list: data }));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
    const page = (
        <>
            <Helmet>

                <script type="application/ld+json">
                    {`
                    {
                        "@type": "VideoObject",
                        "name": "¿Qué es Dólar MEP?",
                        "description": "El Dólar Bolsa o Dólar MEP es el tipo de cambio implícito que resulta de comprar un valor negociable, por ejemplo un bono, utilizando pesos con el propósito de venderlo posteriormente en el mercado en dólares. Lo característico de esta operación, es que la venta del bono se realiza en una moneda diferente a la utilizada en la primera transacción. Dólar MEP se ha convertido en una de las opciones más populares para dolarizar los ahorros, debido a la accesibilidad y seguridad. Operar en Dólar MEP es una forma conveniente y confiable de manejar tus recursos y dolarizarte.",
                        "contentUrl": "https://balanz.com/inversiones/dolar-mep/",
                        "embedUrl": "https://balanz.com/inversiones/dolar-mep/"
                    }
                `}
                </script>
            </Helmet>
            <ProductBanner
                bannerContainerClassName="bannerCauciones"
                bannerTrasparecy="bannerMask"
                productTitle="Dólar MEP"
                bannerImage={Banner}
                bannerImageClassName="caucionesBannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText1"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <section className="fullwidth product-description">
                <Container>
                    <Row className="">
                        <h2>¿Qué es Dólar MEP?</h2>
                    </Row>
                </Container>
                <div className="bg-lt-grey">
                    <Container>
                        <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                            <Col xs={12} >
                                <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">El Dólar Bolsa o Dólar MEP es el tipo de cambio implícito que resulta de comprar un valor negociable, por ejemplo un bono, utilizando pesos con el propósito de venderlo posteriormente en el mercado en dólares. Lo característico de esta operación, es que la venta del bono se realiza en una moneda diferente a la utilizada en la primera transacción.
                                </p>
                                <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Dólar MEP se ha convertido en una de las opciones más populares para dolarizar los ahorros, debido a la accesibilidad y seguridad. Operar en Dólar MEP es una forma conveniente y confiable de manejar tus recursos y dolarizarte.
                                </p>
                                <div className="pt-4 text-center">
                                    
                                    <Button id="dolar-mep_invertir_1" variant="secondary" text="Comprar Dólar MEP" onClick={() => { window.open("https://clientes.balanz.com/auth/login") }} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </section>
            <section className="fullwidth product-description">
                <div className="bg-lt-white">
                    <Container>
                        <Row className="gx-5 gy-4 pt-3 pt-sm-2 pt-md-3 pt-md-4 pt-lg-2">
                            <Col xs={12} >
                                <p className="product-text">
                                <h2 >  Operar Dólar MEP nunca fue tan fácil</h2>
                                    Con un solo click, podés iniciar tu inversión y aprovechar esta oportunidad financiera única:
                                </p>
                                <ul className="tick-list" >
                                    <li className="product-text tick">
                                        <span className="tick-img"></span>
                                        Simple
                                    </li>
                                    <li className="product-text tick">
                                        Seguro
                                    </li>
                                    <li className="product-text tick">
                                        Rápido
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </section>
            <section className="fullwidth razones cauciones">
                <Container>
                    <Row>
                        <h2 className="mt-2">5 razones para invertir en Dólar MEP</h2>

                        <SlickProduct
                            slickSettings={slickSettings}
                            list={slick_items}
                        />
                         <div className="pt-4 text-center">
                         <Link to="https://clientes.balanz.com/auth/login">
                                <Button variant="secondary" text="Invertir en Dólar MEP" />
                            </Link>
                         </div>
                    </Row>
                </Container>
            </section>
            <section className="fullwidth operaciones">
                <Container>

                    <Row className="pt-3">
                        <Col xs={12}>
                        <h2 className="mt-2">Cómo operar Dólar MEP en Balanz</h2>
                            <h4 className="mt-5 primary-color" >Compra del bono:</h4>
                            <p className="base-text" >
                                <ul>
                                    <li >
                                    Hacé click en el banner de Dólar MEP de la App o plataforma Web.
                                    </li>
                                    <li >
                                        Ingresá la cantidad de dólares que querés comprar y confirmá la operación.
                                    </li>
                                    <li >
                                        Aguardá un día hábil de parking  y cuando la compra del bono esté lista, recibirás una notificación.
                                    </li>
                                </ul>

                            </p>
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col xs={12}>
                        <h4 className="mt-2 primary-color" >Venta del bono:</h4>
                            <p className="base-text" >
                                <ul>
                                    <li >
                                    Confirmá la venta del bono desde la App o web.
                                    </li>
                                    <li >
                                    ¡Listo! Ya tenés <span className="highlightsbold">tus dólares disponibles</span> en tu cuenta de inversión Balanz.
                                    </li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
                <Container className="d-flex align-items-center justify-content-center">
                    <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                        <Col xs={12} md={12}>
                            <div className="section-content banner2-Title text-center"
                            >
                                <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Dólar MEP?</strong></span></h3>

                                <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>

                            </div>

                        </Col>
                        <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"

                        >
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta" />
                            </Link>


                        </div>

                    </Row>
                </Container>
            </section>
            <section className="fullwidth preguntas-frecuentes">
                <Container>
                    <Row>
                        <Faq faq={faq} customClass={'dolar-mep-faq'} />
                    </Row>
                </Container>
            </section>
        </>
    )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"dolar-mep"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default DolarMepPage;
